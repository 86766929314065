import styled from "@emotion/styled"
import PropTypes from "prop-types"
import React from "react"

import mq from "../utils/mediaQuery"

const FeatureCell = styled.div`
  ${mq({
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    flexDirection: "row",
    marginBottom: [12, 12, 12],
    lineHeight: ["20px", "30px", "30px"],
    fontSize: [14, 20, 20],
    fontWeight: 300,
  })};
`

const FeatureBulletContainer = styled.div`
  ${mq({
    fontSize: [24, 30, 36],
    marginRight: [8, 8, 8],
    top: ["-1px", "0px", "-2px"],
    position: "relative",
  })};
`

const FeatureContentContainer = styled.span`
  ${mq({})};
`

const FeatureImg = styled.img`
  ${mq({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    width: [48, 80, 120],
    height: [48, 80, 120],
    marginRight: [8, 8, 8],
  })};
`

const Feature = ({ children }) => (
  <FeatureCell>
    <FeatureBulletContainer>•</FeatureBulletContainer>
    <FeatureContentContainer>{children}</FeatureContentContainer>
  </FeatureCell>
)

Feature.propTypes = {
  children: PropTypes.string.isRequired,
}

export default Feature
