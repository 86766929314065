import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import styled from "@emotion/styled"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import PropTypes from "prop-types"
import React from "react"

import Feature from "../components/Feature"
import Hexie from "../components/Hexie"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import mq from "../utils/mediaQuery"

const HexiesGallery = styled.div`
  ${mq({
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    marginTop: ["16px", "16px", "16px"],
    marginBottom: ["16px", "16px", "16px"],
    flexWrap: "wrap",
    width: "100%",
  })};
`

const CharitySection = styled.div`
  ${mq({
    marginTop: ["16px", "16px"],
    marginBottom: ["16px", "16px"],
    width: "100%",
    lineHeight: ["20px", "30px", "30px"],
    fontSize: [12, 18, 18],
  })};
`

const PaletteSection = styled.p`
  ${mq({
    marginTop: ["16px", "16px"],
    marginBottom: ["16px", "16px"],
    width: "100%",
    lineHeight: ["20px", "30px", "30px"],
    fontSize: [12, 18, 18],
  })};
`

const H1 = styled.h1`
  ${mq({
    width: "100%",
    fontSize: [16, 24, 24],
    fontWeight: 500,
    marginTop: ["4px", "8px", "8px"],
    marginBottom: ["8px", "16px", "16px"],
  })};
`

const H2 = styled.h2`
  ${mq({
    width: "100%",
    fontSize: [14, 20, 20],
    fontWeight: 400,
    marginTop: ["4px", "8px", "8px"],
    marginBottom: ["16px", "16px", "16px"],
  })};
`

const P = styled.p`
  ${mq({
    width: "100%",
    fontSize: [14, 20, 20],
    fontWeight: 300,
    marginTop: ["4px", "0px", "0px"],
    marginBottom: ["16px", "16px", "16px"],
  })};
`

const ColoredSpan = styled.span`
  ${mq({
    color: "black",
  })};
`

const StyledA = styled.a`
  ${mq({
    color: "#484848",
    color: "black",
    textDecorations: "none",
    textDecorationColor: "#a5a5a5",
  })};
`

const StyledLink = styled(Link)`
  display: inline;
  align-items: center;
  flex-direction: column;
  color: black;
  -webkit-text-decoration-color: red; /* safari still uses vendor prefix */
  text-decoration: underline;
  text-decoration-color: #a5a5a5;
`

const FeatureSection = styled.div`
  ${mq({
    display: "flex",
    flex: 1,
    alignItems: "flex-start",
    justifyContent: "center",
    flexDirection: "column",
    marginTop: ["6px", "6px", "6px"],
    marginBottom: ["16px", "16px", "32px"],
    width: "100%",
  })};
`

const Index = () => {
  const batch1 = []
  ;[0xb2, 0xd2, 0x15, 0x67].forEach(i => {
    batch1.push(<Hexie key={"batch1" + i} colorNumber={i} />)
  })
  const batch2 = []
  ;[0xe7, 0xad, 0xc2, 0xb9].forEach(i => {
    batch2.push(<Hexie key={"batch2" + i} colorNumber={i} />)
  })
  const batch3 = []
  ;[0x8c, 0x30, 0x6a, 0x18].forEach(i => {
    batch3.push(<Hexie key={"batch3" + i} colorNumber={i} />)
  })
  const batch4 = []
  ;[0x90, 0x0d, 0x66, 0xfc].forEach(i => {
    batch4.push(<Hexie key={"batch4" + i} colorNumber={i} />)
  })

  return (
    <>
      <SEO title="cryptohexies" />
      <Layout title="cryptohexies">
        <H1>A Collection of Finites</H1>
        <FeatureSection>
          <Feature>
            <span>
              <ColoredSpan>
                <b>cryptohexies</b>
              </ColoredSpan>{" "}
              are colors within a collection of 256 unique colors, each
              represented by a hexadecimal number between 0x00-0xFF.
            </span>
          </Feature>
          <Feature>
            Available as{" "}
            <StyledA href="https://en.wikipedia.org/wiki/Non-fungible_token">
              NFTs
            </StyledA>
            , no two colors are the same, and no new colors will ever be issued.
          </Feature>
          <Feature>
            The first release includes 16 of the 256 cryptohexies. All proceeds
            from the initial batch go to charity.
          </Feature>
          <Feature>
            See the full{" "}
            <StyledLink to="/gallery">collection of cryptohexies</StyledLink>.
          </Feature>
        </FeatureSection>
        <H1>Batch #1 - May 15th, 2021</H1>
        <P>
          Our first release of{" "}
          <ColoredSpan>
            <b>cryptohexies</b>
          </ColoredSpan>{" "}
          will include four sets of four{" "}
          <ColoredSpan>
            <b>hexies</b>
          </ColoredSpan>{" "}
          each. Each set is 100% dedicated to a charity. Proceeds will go to the
          following charities:
        </P>
        <CharitySection>
          <H2>
            <StyledA href="https://www.sfspca.org/">San Francisco SPCA</StyledA>
          </H2>
          <P>
            The mission of the San Francisco SPCA is to save and protect
            animals, provide care and treatment, advocate for their welfare and
            enhance the human-animal bond.
          </P>
          <HexiesGallery>{batch1}</HexiesGallery>
        </CharitySection>
        <CharitySection>
          <H2>
            <StyledA href="https://www.nativeartsandcultures.org/">
              Native Arts and Cultures Foundation
            </StyledA>
          </H2>
          <P>
            The Native Arts and Cultures Foundation advances equity and cultural
            knowledge, focusing on the power of arts and collaboration to
            strengthen Native communities and promote positive social change
            with American Indian, Native Hawaiian, and Alaska Native peoples in
            the United States.
          </P>
          <HexiesGallery>{batch2}</HexiesGallery>
        </CharitySection>
        <CharitySection>
          <H2>
            <StyledA href="https://www.oregonhumane.org/">
              Oregon Humane Society
            </StyledA>
          </H2>
          <P>
            The Oregon Humane Society rescues, heals and adopts more than 11,000
            pets each year. We never place a time limit on how long cats, dogs
            and other pets stay at our shelter. OHS relies on donations to
            support our adoption, education, and animal rescue programs.
          </P>
          <HexiesGallery>{batch3}</HexiesGallery>
        </CharitySection>
        <CharitySection>
          <H2>
            <StyledA href="https://www.stjude.org/">
              St. Jude Children's Hospital
            </StyledA>
          </H2>
          <P>
            St. Jude is leading the way the world understands, treats and
            defeats childhood cancer and other life-threatening diseases.
          </P>
          <HexiesGallery>{batch4}</HexiesGallery>
        </CharitySection>
        <PaletteSection>
          See our full <StyledLink to="/palette">palette of colors</StyledLink>.
        </PaletteSection>
      </Layout>
    </>
  )
}

Index.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Index
